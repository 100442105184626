import { createStore } from "vuex"

export default createStore({
  state: {
    user: {}
  },
  getters: {
    getAuthToken(state) {
      return state.user.accessToken
    },
    getUserLogin(state) {
      return state.user.login
    },
    getUserContact(state) {
      return state.user.fullName
    },
    getUserRoles(state) {
      return state.user.roles
    },
  },
  mutations: {
    setUser(state, user) {
      state.user = user
    }
  },
  actions: {},
  modules: {}
})
