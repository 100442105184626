import {createRouter, createWebHistory} from "vue-router";

const routes = [
  {
    path: "/",
    name: "template",
    component: () => import("../Views/PageTemplate"),
    children: [
      {
        path: "/",
        name: "dashboard",
        component: () => import("../components/DashboardVue")
      },
      {
        path: "/cameras",
        name: "cameras",
        component: () => import("../components/Cameras/CamerasList")
      },
      {
        path: "/create-camera",
        name: "create-camera",
        component: () => import("../components/Cameras/CreateCamera.vue")
      },
      {
        path: "/change-camera/:id",
        name: "change-camera",
        component: () => import("../components/Cameras/ChangeCamera")
      },
      {
        path: "/create-user",
        name: "create-user",
        component: () => import("../components/Admin/Users/CreateUser")
      },
      {
        path: "/change-user/:id",
        name: "change-user",
        component: () => import("../components/Admin/Users/ChangeUser")
      },
      {
        path: "/users",
        name: "users",
        component: () => import("../components/Admin/Users/UsersList")
      },
      {
        path: "/locations",
        name: "locations",
        component: () => import("../components/Admin/Locations/LocationsList")
      },
      {
        path: "/organizations",
        name: "organizations",
        component: () => import("../components/Admin/Organizations/OrganizationsList")
      },
      {
        path: "/create-organization",
        name: "create-organization",
        component: () => import("../components/Admin/Organizations/CreateOrganization")
      },
      {
        path: "/change-organization/:id",
        name: "change-organization",
        component: () => import("../components/Admin/Organizations/ChangeOrganization")
      },
      {
        path: "/view-organization/:id",
        name: "view-organization",
        component: () => import("../components/Admin/Organizations/ViewOrganization")
      },
      {
        path: "/tags",
        name: "tags",
        component: () => import("../components/Admin/Tags/TagsList")
      },
      {
        path: "/video-categories",
        name: "video-categories",
        component: () => import("../components/Admin/VideoCategory/CategoryList")
      },
      {
        path: "/podcast-categories",
        name: "podcast-categories",
        component: () => import("../components/Admin/PodcastCategory/PodcastCategoryList")
      },
      {
        path: "/parser",
        name: "parser",
        component: () => import("../components/Parser/ParserList")
      },
      {
        path: "/news",
        name: "news",
        component: () => import("../components/News/NewsList")
      },
      {
        path: "/news/:id",
        name: "news-gallery",
        component: () => import("../components/News/NewsGallery")
      },
      {
        path: "/create-news/:id",
        name: "create-news",
        component: () => import("../components/News/CreateNews")
      },
      {
        path: "/change-news/:id",
        name: "change-news",
        component: () => import("../components/News/ChangeNews")
      },
      {
        path: "/poster",
        name: "poster",
        component: () => import("../components/Posters/PostersList")
      },
      {
        path: "/update-poster/:id",
        name: "update-poster",
        component: () => import("../components/Posters/UpdatePoster.vue")
      },
      {
        path: "/create-poster/:id",
        name: "create-poster",
        component: () => import("../components/Posters/CreatePoster.vue")
      },
      {
        path: "/banners",
        name: "banners",
        component: () => import("../components/Banners/BannersList")
      },
      {
        path: "/albums",
        name: "albums",
        component: () => import("../components/Albums/AlbumsList")
      },
      {
        path: "/album/:id/photos",
        name: "photos",
        component: () => import("../components/Albums/AlbumsPhotos")
      },
      {
        path: "/video",
        name: "video",
        component: () => import("../components/Videos/VideosList")
      },
      {
        path: "/create-video",
        name: "create-video",
        component: () => import("../components/Videos/CreateVideo")
      },
      {
        path: "/change-video/:id",
        name: "change-video",
        component: () => import("../components/Videos/ChangeVideo")
      },
      {
        path: "/podcasts",
        name: "podcasts",
        component: () => import("../components/Podcasts/PodcastsList")
      },
      {
        path: "/create-podcast",
        name: "create-podcast",
        component: () => import("../components/Podcasts/CreatePodcast")
      },
      {
        path: "/change-podcast/:id",
        name: "change-podcast",
        component: () => import("../components/Podcasts/ChangePodcast")
      },
      {
        path: "/broadcast",
        name: "broadcast",
        component: () => import("../components/Broadcasts/BroadcastsOptions")
      },
      {
        path: "/running-line",
        name: "running-line",
        component: () => import("../components/RunningLine/RunningLine")
      },
      {
        path: "/error-403-page",
        name: "error-403",
        component: () => import("../Views/ErrorPage403")
      },
    ]
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../Views/LoginPage.vue")
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
  }
});
// remove modal backdrop if one exists
router.beforeEach((to, from, next) => {
  let modalBackground = document.querySelector('.modal-backdrop')
  if (modalBackground) {
    modalBackground.remove()
  }
  next()
})

export default router;
