<template>
  <div class="wrapper">
    <router-view />
  </div>
</template>

<script>
export default {
  created() {
    const user = localStorage.getItem("user")
    if (!user) {
      this.$router.push({ name: "login" })
    }
    this.$store.commit("setUser", JSON.parse(user))
  }
}
</script>
<style>
@import "~bootstrap/dist/css/bootstrap.min.css";
@import "~admin-lte/dist/css/adminlte.min.css";
.pointer {
  cursor: pointer;
}
.zero-index {
  z-index: 0;
}
</style>
