import {createApp} from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueSweetalert2 from 'vue-sweetalert2';

import 'bootstrap/dist/js/bootstrap.bundle.min'
import 'admin-lte/dist/js/adminlte.min'
import 'jodit/build/jodit.min.css'
import '@vuepic/vue-datepicker/dist/main.css'
import 'jquery/dist/jquery.min'

import 'sweetalert2/dist/sweetalert2.min.css';
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/css/regular.css'
import '@fortawesome/fontawesome-free/css/solid.css'


createApp(App).use(store).use(router).use(VueSweetalert2).mount("#app");
